import React from 'react'
import {
  Divider,
  ListItemIcon,
  MenuItem,
  SvgIcon,
  Typography,
} from '@mui/material'
import { withStyles } from 'tss-react/mui'
import BottomSheet from '../common/BottomSheet'
import {
  mdiApple,
  mdiCalendarPlus,
  mdiGoogle,
  mdiMicrosoftOffice,
  mdiMicrosoftOutlook,
  mdiYahoo,
} from '@mdi/js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEvent } from '../../selectors/event'
import { google, ics, office365, outlook, yahoo } from 'calendar-link'
import { closeCalendarModal } from '../../actions/modals'
import ContinueInAppButton from '../common/ContinueInAppButton'
import useMixpanelTrackEvent from '../../utils/useMixpanelTrackEvent'

const calendarStyles = (theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  calendarIcon: {
    marginRight: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(3, -3, 3, -3),
  },
  continueButton: {
    marginTop: theme.spacing(2),
  },
  listContainer: {
    margin: theme.spacing(3, -3, 0, -3),
  },
  menuItem: {
    padding: theme.spacing(2, 3),
    fontWeight: 600,
  },
  menuIcon: {
    marginRight: theme.spacing(2),
    minWidth: '24px',
  },
})

const calendarOptions = [
  {
    name: 'Google',
    makeLink: google,
    icon: (
      <SvgIcon color='primary'>
        <path d={mdiGoogle} />
      </SvgIcon>
    ),
  },
  {
    name: 'iCal',
    makeLink: ics,
    icon: (
      <SvgIcon style={{ color: '#000000' }}>
        <path d={mdiApple} />
      </SvgIcon>
    ),
  },
  {
    name: 'Outlook',
    makeLink: outlook,
    icon: (
      <SvgIcon style={{ color: '#0078D4' }}>
        <path d={mdiMicrosoftOutlook} />
      </SvgIcon>
    ),
  },
  {
    name: 'Office 365',
    makeLink: office365,
    icon: (
      <SvgIcon style={{ color: '#E33F20' }}>
        <path d={mdiMicrosoftOffice} />
      </SvgIcon>
    ),
  },
  {
    name: 'Yahoo!',
    makeLink: yahoo,
    icon: (
      <SvgIcon style={{ color: '#6F1DD3' }}>
        <path d={mdiYahoo} />
      </SvgIcon>
    ),
  },
]

const transformEvent = (event) => {
  const eventDescription =
    `${process.env.NEXT_PUBLIC_RSVP}/${event.code}\n\n` +
    (event.description ? event.description : '')
  const calendarEvent = {
    description: eventDescription,
    start: event.startDate,
    location: event.location,
    title: event.name,
  }
  if (event.endDate === '' || event.endDate == null) {
    return { ...calendarEvent, duration: [3, 'hour'] }
  }
  return { ...calendarEvent, end: event.endDate }
}

const AddToCalendarModal = ({ classes }) => {
  // const classes = useCalendarStyles()
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const trackEvent = useMixpanelTrackEvent()
  const event = useSelector(getCurrentEvent)
  const open = useSelector((state) => state.modals.open.addToCalendar)

  const calendarEvent = transformEvent(event)

  const makeOnClickCalendarItem = (calendarName, makeLink) => () => {
    trackEvent('Add to Calendar', { 'Calender Type': calendarName })
    const link = makeLink(calendarEvent)
    window.open(link, calendarName === 'iCal' ? '_self' : '_blank')
  }

  return (
    <BottomSheet
      open={open}
      className={classes.root}
      onClose={() => dispatch(closeCalendarModal())}
    >
      <div className={classes.titleContainer}>
        <SvgIcon className={classes.calendarIcon} color='primary'>
          <path d={mdiCalendarPlus} />
        </SvgIcon>
        <Typography variant='h6'>{t('addToCalendar')}</Typography>
      </div>
      <Divider className={classes.divider} />
      <Typography variant='subtitle2'>
        {t('syncCalendarAutomatically')}
      </Typography>
      <ContinueInAppButton
        className={classes.continueButton}
        action='RSVP_calendar_modal'
      />
      <div className={classes.listContainer}>
        {calendarOptions.map(({ name, makeLink, icon }) => (
          <div key={name + 'div'}>
            <Divider key={name + 'divider'} />
            <MenuItem
              key={name}
              onClick={makeOnClickCalendarItem(name, makeLink)}
              className={classes.menuItem}
            >
              <ListItemIcon key={name + 'icon'} className={classes.menuIcon}>
                {icon}
              </ListItemIcon>
              {name}
            </MenuItem>
          </div>
        ))}
      </div>
    </BottomSheet>
  )
}

export default withStyles(AddToCalendarModal, calendarStyles)
