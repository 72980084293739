import React from 'react'
import { GetApp } from '@mui/icons-material'
import FlatButton from '../../shared-components/buttons/FlatButton'
import { useDownloadApp } from '../../shared-components/utils/downloadApp'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Bowser from 'bowser'
import { useSelector } from 'react-redux'
import { getCurrentEvent } from '../../selectors/event'
import { withSplitTreatments } from '@splitsoftware/splitio-react'
import i18n from 'i18next'
import { Typography } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

let md
if (typeof window !== 'undefined') {
  md = Bowser.getParser(window.navigator.userAgent)
}

const useStyles = makeStyles()((theme) => ({
  black: {
    background: 'black',
    color: '#fff',
  },
  badge: {
    width: 16,
    height: 16,
    borderRadius: '100px',
    background: theme.palette.red.main,
    marginLeft: theme.spacing(1),
  },
  badgeSmall: {
    width: 10,
    height: 10,
    borderRadius: '100px',
    background: '#FF8784',
    marginLeft: theme.spacing(1),
  },
  badgeText: {
    lineHeight: '16px',
  },
}))

const ContinueInAppButton = ({
  action,
  startIcon,
  callback,
  text,
  treatments,
  isReady,
  className,
  fullWidth = true,
  badgeNumber,
  ...other
}) => {
  const { t } = useTranslation('common')
  const { classes, cx } = useStyles()
  const event = useSelector(getCurrentEvent)
  const treatment = treatments.continue_in_app_copy.treatment
  const icon = startIcon || <GetApp />
  const { onDownloadClick, QRCodeModal } = useDownloadApp({
    utm_medium: 'continue-in-app-button',
    event,
    usingHref: false,
  })

  const onClick = () => {
    callback ? callback(action, md?.getOSName()) : onDownloadClick()
  }

  return (
    <>
      <FlatButton
        className={cx(className, {
          [classes.black]:
            treatment === 'join_feestje' || treatment === 'download_app_black',
        })}
        color='secondary'
        fullWidth={fullWidth}
        onClick={onClick}
        startIcon={icon}
        endIcon={fullWidth ? undefined : <div />}
        {...other}
      >
        {text || t('downloadAppButton')}
        {badgeNumber !== undefined && (
          <div
            className={badgeNumber === 0 ? classes.badgeSmall : classes.badge}
          >
            {badgeNumber > 0 && (
              <Typography className={classes.badgeText} variant='caption'>
                {badgeNumber}
              </Typography>
            )}
          </div>
        )}
      </FlatButton>
      <QRCodeModal />
    </>
  )
}

ContinueInAppButton.propTypes = {
  action: PropTypes.string.isRequired,
}

export default withSplitTreatments(['continue_in_app_copy'], {
  isDutch: i18n.language === 'nl',
})(ContinueInAppButton)
